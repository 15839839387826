<template>
  <div :id="`duHolder_${id}`" />
</template>
<script>
import 'babel-polyfill'
import 'es6-promise/auto'

function handleOnChange (e) {
  const text = e.options[e.selectedIndex].text
  // console.log(e.options)
  // console.log(text)
}
export default {
  name: 'DEXT5UploadForOog',
  props: {
    single: { type: Boolean, default: false, required: false },
    category: { type: Boolean, default: false, required: false },
    id: { type: String, default: '', required: false },
    width: { type: String, default: '100%', required: false },
    height: { type: String, default: '200px', required: false },
    fileInfo: { type: Array, required: false },
    categoryInfo: { type: Array, required: false }
  },
  data () {
    return {
      index: 0,
      makeDext: false,
      options: {}
    }
  },
  watch: {
    fileInfo: function () {
      console.log('파일인포 변경=', JSON.stringify(this.fileInfo))
    }
  },
  destroyed () {
    console.log('destroyed')
    if (this.options.id) {
      DEXT5UPLOAD.Destroy(this.options.id)
    }
  },
  mounted () {
    console.log('dext5 mount')

    let handlerUrl = ''
    if (process.env.VUE_APP_COMMON_URL.startsWith('http')) {
      handlerUrl = process.env.VUE_APP_COMMON_URL
    } else if (process.env.VUE_APP_COMMON_URL.startsWith('//')) {
      handlerUrl = location.protocol + process.env.VUE_APP_COMMON_URL
    }
    handlerUrl += '/commons/dext5/handler'

    const duOptions = {
      type: this.type,
      id: this.id,
      width: this.width || '100%',
      height: this.height || '500px',
      uploadHolder: 'duHolder_' + this.id,
      handlerUrl: handlerUrl, //process.env.VUE_APP_COMMON_URL + '/commons/dext5/handler',
      runtimes: 'html5',
      buttonBarEdit: 'download',
      event: {
        CreationComplete: this.onCreationComplete,
        TransferComplete: this.onTransferComplete,
        BeforeFileDownload: this.beforeFileDownload,
        FinishDownloaded: this.FinishDownloaded,
        AfterAddItemEndTime: this.AfterAddItemEndTime,
        AfterAddItem: this.AfterAddItem,
        BeforeDeleteItem: this.BeforeDeleteItem,
        BeforeAddItem: this.BeforeAddItem
      }
    }

    if (typeof (DEXT5UPLOAD) === 'undefined') {
      this.$loadScript('/dext5upload/js/dext5upload.js')
        .then(() => {
          this.MakeUpload(duOptions)
        })
        .catch(() => {
          console.log('load fail catch')
        })
    } else {
      this.MakeUpload(duOptions)
    }
    console.log('dext5 mount end')
  },
  methods: {
    ResetDext: function () {
      let handlerUrl = ''
      if (process.env.VUE_APP_COMMON_URL.startsWith('http')) {
        handlerUrl = process.env.VUE_APP_COMMON_URL
      } else if (process.env.VUE_APP_COMMON_URL.startsWith('//')) {
        handlerUrl = location.protocol + process.env.VUE_APP_COMMON_URL
      }
      handlerUrl += '/commons/dext5/handler'

      const duOptions = {
        type: this.type,
        id: this.id,
        width: this.width || '100%',
        height: this.height || '500px',
        uploadHolder: 'duHolder_' + this.id,
        handlerUrl: handlerUrl,
        runtimes: 'html5',
        buttonBarEdit: 'download',
        event: {
          CreationComplete: this.onCreationComplete,
          TransferComplete: this.onTransferComplete,
          BeforeFileDownload: this.beforeFileDownload,
          FinishDownloaded: this.FinishDownloaded,
          AfterAddItemEndTime: this.AfterAddItemEndTime,
          AfterAddItem: this.AfterAddItem,
          BeforeDeleteItem: this.BeforeDeleteItem,
          BeforeAddItem: this.BeforeAddItem
        }
      }

      if (typeof (DEXT5UPLOAD) === 'undefined') {
        this.$loadScript('/dext5upload/js/dext5upload.js')
          .then(() => {
            this.MakeUpload(duOptions)
          })
          .catch(() => {
            console.log('load fail catch')
          })
      } else {
        this.MakeUpload(duOptions)
      }
    },
    MakeUpload: function (options) {
      // console.log(JSON.stringify(options))
      if (DEXT5UPLOAD.GetUploadByName(options.id) !== null) {
        console.log('destroy id = ', options.id)
        DEXT5UPLOAD.Destroy(options.id)
      }

      this.options = options
      // console.log('makeUpload = ', JSON.stringify(this.options))
      DEXT5UPLOAD.config.Width = options.width
      DEXT5UPLOAD.config.Height = options.height
      DEXT5UPLOAD.config.Runtimes = options.runtimes
      DEXT5UPLOAD.config.Event = options.event
      DEXT5UPLOAD.config.UploadHolder = options.uploadHolder
      DEXT5UPLOAD.config.ButtonBarEdit = options.buttonBarEdit
      DEXT5UPLOAD.config.ListViewDragAndDrop = '0'
      DEXT5UPLOAD.config.ListViewDbclick = '0'
      DEXT5UPLOAD.config.RemoveContextItem = 'add_file,remove_current_file,remove_all_file'
      if (this.category) {
        DEXT5UPLOAD.config.HeaderBarItem = '카테고리'
        DEXT5UPLOAD.config.HeaderBarItemWidth = '150'
        DEXT5UPLOAD.config.HeaderBarItemAlign = 'center'
      }

      if (options.handlerUrl !== '') {
        DEXT5UPLOAD.config.HandlerUrl = options.handlerUrl
      }

      this.dext5 = new Dext5Upload(options.id)
    },
    SendFile: function () {
      // DEXT5UPLOAD.AddUploadedFile('test', originName, path, size, customValue, this.Id)
      DEXT5UPLOAD.Transfer(this.id)
    },
    // DEXT5 Upload Event
    onCreationComplete: function (uploadId) {
      // console.log('onCreationComplete ' + uploadId)
      console.log('@@@@@@@@@@@@ created start ')
      let $vm = this

      for (let i = 0; i < this.fileInfo.length; i++) {
        DEXT5UPLOAD.AddUploadedFile(this.index, this.fileInfo[i].originalName, '/', this.fileInfo[i].size, this.fileInfo[i].fileId, uploadId)
        DEXT5UPLOAD.SetUploadedFile(this.index, this.fileInfo[i].guid, this.fileInfo[i].originalName, '/', this.fileInfo[i].size, this.fileInfo[i].fileId, uploadId)
        this.index = this.index + 1
      }
      // console.log('onCreationComplete end')

      let fileListObj = DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document.getElementById('file_list')

      if (fileListObj) {
        if (this.category) {
          // console.log('this.category= ' + this.category)
          // console.log('this.categoryInf= ' + JSON.stringify(this.categoryInfo))
          let size = fileListObj.childNodes.length

          for (let i = 0; i < size; i++) {
            // console.log('@@@@@@@@ index = ', i)
            let customHeaderObj = fileListObj.childNodes[i].childNodes[0].childNodes[3].childNodes[0]
            customHeaderObj.style.textAlign = 'center'
            // customHeaderObj.innerHTML =
            let tempSelect = '<select style="height:15px;" id="' + $vm.fileInfo[i].guid + '">'

            // console.log('00000')
            // console.log('customHeaderObj.innerHTML = ' + tempSelect)
            for (let j = 0; j < this.categoryInfo.length; j++) {
              // console.log('iii= ', j)
              // console.log('this.categoryInfo[i] = ' + this.categoryInfo[j].value)
              // console.log('this.fileInfo[i].category = ' + this.fileInfo[i].category)
              //선택처리
              if (this.categoryInfo[j].value === this.fileInfo[i].category) {
                tempSelect += '<option value="' + this.categoryInfo[j].value + '" selected>' + this.categoryInfo[j].category + '</option>'
              } else {
                tempSelect += '<option value="' + this.categoryInfo[j].value + '" >' + this.categoryInfo[j].category + '</option>'
              }

              // console.log('customHeaderObj.innerHTML = ' + tempSelect)
            }

            tempSelect += '</select>'
            // console.log('444444= ', tempSelect)
            customHeaderObj.innerHTML = tempSelect
            let dextCategory = DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document.getElementById($vm.fileInfo[i].guid)
            dextCategory.onchange = function selectChange (e) {
              // console.log('select = ', dextCategory.value)
              // console.log('$vm.fileInfo= ' + JSON.stringify($vm.fileInfo))
              $vm.fileInfo[i].category = dextCategory.value
              // console.log('$vm.fileInfo[' + i + ']= ' + JSON.stringify($vm.fileInfo[i]))
              // $vm.$emit('update', $vm.fileInfo)
            }
            // console.log(dextCategory)
            // console.log('customHeaderObj.innerHTML', customHeaderObj.innerHTML)
          }
        }
      }
      this.makeDext = true
      console.log('@@@@@@@@@@@@ created end ')
    },
    AfterAddItem: function (uploadID, strFileName, nFileSize, nAddItemIndex, objFile) {
      // console.log('AfterAddItem')
    },
    onTransferComplete: function (uploadId) {
      console.log('onTransferComplete ' + uploadId)
      let jsonAll = DEXT5UPLOAD.GetAllFileListForJson(uploadId)

      // console.log('jsonAll ' + JSON.stringify(jsonAll))
      let originalName = jsonAll.newFile.originalName
      let size = jsonAll.newFile.size
      let guid = jsonAll.newFile.guid
      let fileId = jsonAll.newFile.responseCustomValue
      let status = jsonAll.newFile.status
      let contentType = jsonAll.newFile.mimeType
      for (let i = 0; i < originalName.length; i++) {
        let dextCategory = DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document.getElementById(jsonAll.newFile.guid)
        this.fileInfo.push({
          guid: guid[i],
          originalName: originalName[i],
          size: size[i],
          fileId: fileId[i],
          status: status[i],
          contentType: contentType[i],
          category: '',
          new: true
        })
        DEXT5UPLOAD.SetUploadedFile(this.index, guid[i], originalName[i], '/', size[i], fileId[i], uploadId)
        this.index = this.index + 1
      }

      let $vm = this

      let fileListObj = DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document.getElementById('file_list')

      if (fileListObj) {
        if (this.category) {
          // console.log('this.category= ' + this.category)
          // console.log('this.categoryInf= ' + JSON.stringify(this.categoryInfo))
          let size = fileListObj.childNodes.length

          for (let i = 0; i < size; i++) {
            // console.log('@@@@@@@@ index = ', i, fileListObj.childNodes[i].childNodes[0].childNodes[3].childNodes[0])
            let customHeaderObj = fileListObj.childNodes[i].childNodes[0].childNodes[3].childNodes[0]
            customHeaderObj.style.textAlign = 'center'
            // customHeaderObj.innerHTML =
            let tempSelect = '<select style="height:15px;" id="' + $vm.fileInfo[i].guid + '">'

            // console.log('00000')
            // console.log('customHeaderObj.innerHTML = ' + tempSelect)
            // console.log('this.fileInfo[i].category = ' + this.fileInfo[i].category)
            if (this.fileInfo[i].category === '') {
              // console.log('미선택')
              tempSelect += '<option value="" selected>선택</option>'
            }
            for (let j = 0; j < this.categoryInfo.length; j++) {
              // console.log('iii= ', j)
              // console.log('this.categoryInfo[i] = ' + this.categoryInfo[j].value)
              // console.log('this.fileInfo[i].category = ' + this.fileInfo[i].category)
              if (this.categoryInfo[j].value === this.fileInfo[i].category) {
                tempSelect += '<option value="' + this.categoryInfo[j].value + '" selected>' + this.categoryInfo[j].category + '</option>'
              } else {
                tempSelect += '<option value="' + this.categoryInfo[j].value + '" >' + this.categoryInfo[j].category + '</option>'
              }

              // console.log('customHeaderObj.innerHTML = ' + tempSelect)
            }

            tempSelect += '</select>'
            // console.log('444444= ', tempSelect)
            customHeaderObj.innerHTML = tempSelect
            let dextCategory = DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document.getElementById($vm.fileInfo[i].guid)
            dextCategory.onchange = function selectChange (e) {
              // console.log('select = ', dextCategory.value)
              // console.log('$vm.fileInfo= ' + JSON.stringify($vm.fileInfo))
              $vm.fileInfo[i].category = dextCategory.value
              // console.log('$vm.fileInfo[' + i + ']= ' + JSON.stringify($vm.fileInfo[i]))
              //$vm.$emit('update', $vm.fileInfo)
            }
            // console.log(dextCategory)
            // console.log('customHeaderObj.innerHTML', customHeaderObj.innerHTML)
          }
        }
      }

      this.$emit('completed', this.fileInfo)
      // this.$emit('update', this.fileInfo)
      // console.log('update')
    },
    beforeFileDownload: function (uploadID, nWebFile, strItemKey, strItemOrgName, strItemUrlOrPath, largeFiles, isLastEvent) {
      console.log('beforeFileDownload  ' + uploadID)
      return true
    },
    FinishDownloaded: function (uploadID, nDownloadItemCount) {
      console.log('FinishDownloaded  ' + uploadID, nDownloadItemCount)
    },
    AfterAddItemEndTime: function (uploadID) {
      console.log('afteradditemendtime')
      DEXT5UPLOAD.TransferEx(uploadID)
    },
    BeforeAddItem: function (uploadID, strFileName, nFileSize, nAddItemIndex, strFileLocalPath, objFile) {
      // console.log('this.single=', this.single)
      if (this.single) {
        let count = DEXT5UPLOAD.GetTotalFileCount(uploadID)
        // console.log('nAddItemIndex = ', nAddItemIndex)
        if (count > 1 || nAddItemIndex > 1) {
          let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: '확인',
            useIcon: false,
            customClass: 'simple_alert',
            message: '하나의 파일만 업로드해주세요',
            type: 'info'
          }
          alert('하나의 파일만 업로드해주세요')
          return false
        }
      }
    },
    BeforeDeleteItem: function (uploadID, strWebFile, strItemKey, strItemUrlOrPath, nDeleteItemIndex) {
      console.log('strItemKey = ' + strItemKey)
      console.log('nDeleteItemIndex = ' + nDeleteItemIndex)
      for (let i = 0; i < this.fileInfo.length; i++) {
        if (i + 1 === nDeleteItemIndex) {
          this.fileInfo.splice(i, 1)
        }
      }
      // this.$emit('update', this.fileInfo)
    },
    getFileInfo: function () {
      return this.fileInfo
    },
    deleteAll: function () {
      console.log('close')
      DEXT5UPLOAD.DeleteAllFile(this.options.id)
    },
    setFileInfo: function () {
      console.log('@@@@@@@@@@@@@ dext5upload setfileInfo')
      let $vm = this
      if (this.makeDext) {
        console.log('DEXT5UPLOAD is defined')

        // console.log('DEXT5UPLOAD = ', DEXT5UPLOAD)
        for (let i = 0; i < this.fileInfo.length; i++) {
          DEXT5UPLOAD.AddUploadedFile(this.index, this.fileInfo[i].originalName, '/', this.fileInfo[i].size, this.fileInfo[i].fileId, this.options.id)
          DEXT5UPLOAD.SetUploadedFile(this.index, this.fileInfo[i].guid, this.fileInfo[i].originalName, '/', this.fileInfo[i].size, this.fileInfo[i].fileId, this.options.id)
          this.index = this.index + 1
        }
        // console.log('onCreationComplete end')

        let fileListObj = DEXT5UPLOAD.GetUploadByName(this.options.id).frameWin.document.getElementById('file_list')

        if (fileListObj) {
          if (this.category) {
            // console.log('$vm.category= ' + $vm.category)
            // console.log('$vm.categoryInf= ' + JSON.stringify($vm.categoryInfo))
            let size = fileListObj.childNodes.length

            for (let i = 0; i < size; i++) {
              // console.log('@@@@@@@@ index = ', i)
              let customHeaderObj = fileListObj.childNodes[i].childNodes[0].childNodes[3].childNodes[0]
              customHeaderObj.style.textAlign = 'center'
              // customHeaderObj.innerHTML =
              let tempSelect = '<select style="height:15px;" id="' + this.fileInfo[i].guid + '">'

              // console.log('00000')
              // console.log('customHeaderObj.innerHTML = ' + tempSelect)
              if (this.fileInfo[i].category === '') {
                // console.log('미선택')
                tempSelect += '<option value="" selected>선택</option>'
              }
              for (let j = 0; j < this.categoryInfo.length; j++) {
                // console.log('iii= ', j)
                // console.log('$vm.categoryInfo[i] = ' + $vm.categoryInfo[j].value)
                // console.log('$vm.fileInfo[i].category = ' + $vm.fileInfo[i].category)
                //선택처리

                if (this.categoryInfo[j].value === this.fileInfo[i].category) {
                  tempSelect += '<option value="' + this.categoryInfo[j].value + '" selected>' + this.categoryInfo[j].category + '</option>'
                } else {
                  tempSelect += '<option value="' + this.categoryInfo[j].value + '" >' + this.categoryInfo[j].category + '</option>'
                }

                // console.log('customHeaderObj.innerHTML = ' + tempSelect)
              }

              tempSelect += '</select>'
              // console.log('444444= ', tempSelect)
              customHeaderObj.innerHTML = tempSelect
              let dextCategory = DEXT5UPLOAD.GetUploadByName(this.options.id).frameWin.document.getElementById($vm.fileInfo[i].guid)
              dextCategory.onchange = function selectChange (e) {
                // console.log('select = ', dextCategory.value)
                // console.log('$vm.fileInfo= ' + JSON.stringify($vm.fileInfo))
                $vm.fileInfo[i].category = dextCategory.value
                // console.log('$vm.fileInfo[' + i + ']= ' + JSON.stringify($vm.fileInfo[i]))
                // $vm.$emit('update', $vm.fileInfo)
              }
              // console.log(dextCategory)
              // console.log('customHeaderObj.innerHTML', customHeaderObj.innerHTML)
            }
          }
        }
      } else {
        console.log('DEXT5UPLOAD is undefined')
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
