<template>
  <div class="popup_wrap" style="width:800px; height:520px;">
    <button
      class="layer_close"
      @click="popClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        파일첨부
      </h1>
      <div class="content_box">
        <DEXT5Upload
          ref="dextupload"
          :id="component_id"
          width="100%"
          height="200px"
          :category="false"
          :single="singleFileYn"
          :file-info="parentInfo"
          :category-info="[]"
        />
      </div>
    </div>
    <div class="mt10 text_center">
      <a class="button blue lg" @click="popClose()">닫기</a>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/common/DEXT5UploadForOog'

export default {
  name: 'SpecialRfDgDownloadPop',
  components: { DEXT5Upload },
  props: {
    singleFileYn: {
      type: Boolean,
      default: false
    },
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    component_id () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    }
  },
  created () {
    console.log(this.parentInfo)
    // test
    //this.fileInfo.guid[i], this.fileInfo.originalName[i], '/', this.fileInfo.size[i], this.fileInfo.fileId[i], uploadId
    // this.fileInfo = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: '123', status: 'complete' }, { guid: '129C42D8-0F13-AE72-2D32-85D94A8393FC', originalName: '스크린샷 2021-08-04 오후 1.15.45.png', size: '84930', fileId: '124', status: 'complete' }]
  },
  methods: {
    setFileInfo: function (parentInfo) {
      let $vm = this

      if (this.$refs.dextupload !== undefined) {
        this.$refs.dextupload.setFileInfo(parentInfo)
        // this.$emit('arrange')
      }
    },
    popClose: function () {
      let file = this.$refs.dextupload.getFileInfo()
      this.$refs.dextupload.ResetDext()
      this.$emit('selectFunc', file)

      this.$emit('close')

      return false
    }
  }
}
</script>
